.modal-m {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  display: -webkit-flex;
}

.modal-m.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-m.exit {
  opacity: 0;
}

.modal-m-content {
  width: 25rem;
  height: 100vh;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  transition: all 0.3s ease-in-out;
  transform: translateX(-400px);
  display: flex;
  flex-direction: column;
}

.modal-m.enter-done .modal-m-content {
  transform: translateX(0px);
}

.modal-m.exit .modal-m-content {
  transform: translateX(-400px);
}

.modal-m-header {
  height: 3rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: rgb(255, 255, 255);
}

.modal-m-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
}

.modal-m-title span {
  font-size: 0.8rem;
  color: rgb(166, 166, 166);
  text-transform: uppercase;
}

.modal-m-close {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(104, 104, 104);
  border-image: initial;
  border-radius: 50%;
  transition: opacity 0.25s ease-in 0s;
}

.modal-m-close span {
  margin-top: -2px;
}

.modal-m-body {
  -webkit-flex: 1;
  flex: 1;
  overflow-y: auto;
}

.modal-m-body-view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  margin: 0.5rem 1.25rem;
  margin-top: 2.25rem;
  height: 1rem;
}

.modal-m-body-view img {
  width: 1rem;
  height: auto;
  margin-right: 0.5rem;
}

.modal-m-body-view h5 {
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: #a6a6a6;
  text-transform: uppercase;
}

.modal-m-body-view-mode {
  background: #fff;
  margin: 1.25rem 0.75rem;
  margin-top: 0;
  border-radius: 0.375rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05), 0 2.5px 6px rgba(0, 0, 0, 0.15);
}

.modal-m-body-view-mode li a {
  padding: 0.8rem 0;
  margin: 0 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  text-decoration: none;
  color: #686868;
  font-size: 0.95rem;
}

.modal-m-body-view-mode li img {
  opacity: 0.15;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

.modal-m-footer {
  background: #4b324a;
  min-height: 3.2rem;
  box-shadow: 0 -3px 7px rgba(0, 0, 0, 0.15);
  position: relative;
}

.modal-m-footer h3 {
  color: #fff;
  padding: 0.4rem 0.5rem;
  font-size: 0.8rem;
  margin: 0;
}

.modal-m-footer ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
}

.modal-m-footer ul li {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 1;
  flex: 1;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.modal-m-footer ul li a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
}

.modal-m-footer ul li a p {
  color: #e56970;
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
}

.modal-m-footer img {
  width: 1rem;
  height: auto;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  margin-right: 0.2rem;
}
