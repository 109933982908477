.f-panzoom {
  /* height: calc(var(--vh, 1vh) * 100); */
  cursor: grab !important;
}
.f-panzoom__content {
  display: flex;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
}
.f-panzoom__content img {
  object-fit: contain;
  max-height: calc(100dvh - 4rem);
}

.f-panzoom__content > div {
  width: 21rem;
  height: 492px;
}

.f-carousel__nav .f-button.is-prev,
.f-carousel__nav .f-button.is-next,
.fancybox__nav .f-button.is-prev,
.fancybox__nav .f-button.is-next {
  z-index: 0;
}

#kapoZoom .f-carousel__nav .f-button {
  background-color: #2e5dea;
}

#kapoZoom .f-carousel__nav .f-button:hover {
  background-color: #2e5dea;
  opacity: 0.8;
}

#kapoZoom .f-carousel__nav {
  --f-carousel-theme-color: #fff;
}

#kapoZoom {
  --f-button-bg: #fff;
  --f-carousel-theme-color: #fff;
  --f-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-border-radius: 20%;

  color: #32325d;

  max-width: 552px;
  cursor: grab;
  position: relative;
  width: 100%;
  height: 100%;
}

#kapoZoom .f-carousel__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:350px){
  #kapoZoom .f-panzoom__content img {
    width: 18rem;
    height: 400px;
  }

  #kapoZoom .f-panzoom__content > div {
    width: 18rem;
    height: 400px;
  }
}

@media screen and (min-width: 768px) {
  #kapoZoom {
    width: 91.5%;
    max-width: 64rem /* 1024px */;
  }
}

@media screen and (min-width: 1400px) {
  #kapoZoom .f-panzoom__content img {
    /* width: w-2/4; */
    width: 33rem;
    height: 750px;
  }

  #kapoZoom .f-panzoom__content > div {
    width: 33rem;
    height: 750px;
  }

  #kapoZoom .f-carousel__nav .f-button.is-prev {
    margin-left: calc(50vw - 37rem);
  }

  #kapoZoom .f-carousel__nav .f-button.is-next {
    margin-right: calc(50vw - 37rem);
  }
}

@media screen and (max-width: 44rem) {
  #kapoZoom .f-carousel__nav .f-button.is-prev {
    margin-left: -10px;
  }

  #kapoZoom .f-carousel__nav .f-button.is-next {
    margin-right: -10px;
  }

  #kapoZoom .f-carousel__nav .f-button {
    background-color: #000;
    opacity: 0.4;
  }

  #kapoZoom .f-carousel__nav .f-button:hover {
    background-color: #000;
    opacity: 0.4;
  }
}

/* Description */

.kapo_title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #8a5c87;
  text-align: center;
  line-height: 1.2;
  padding: 0.75rem;
  margin-top: 1rem;
}

.kapo_ul {
  max-height: 50%;
  overflow-y: auto;
  margin: 1rem auto;
  background: #f9f9f9;
}

.kapo_li {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0.5rem;
}

.kapo_li > div {
  width: 30px;
  height: 100%;
  margin-right: 0.2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.kapo_img {
  width: 100% !important;
  height: 100% !important;
  opacity: 1;
}

.kapo_li p {
  font-size: 0.9rem !important;
  line-height: 1.25;
  padding-left: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kapo_span {
  display: block;
  font-weight: 600;
  text-align: center;
}

.kapo_icon_img {
  width: 64px !important;
  height: 64px !important;
}