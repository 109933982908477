* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.error404 {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error404 img {
    max-width: 80%;
    max-height: 50%;
}

.error404 h1 {
    font-size: 1.5rem !important; 
    margin: 2.5rem;
}

.error404 button {
    background-color: #27ae60;
    color: white;
    padding: 0.75rem 1rem;
    margin: 2.5rem;
    border: 0;
    border-radius: 0.25rem;
}