.modal-welcome {
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.3); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-welcome-body {
    border-radius: 0.375rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 2.5px 6px rgba(0, 0, 0, 0.25);
    width: 20rem;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
}

.modal-welcome-header {
    height: 3rem;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    background: rgb(255, 255, 255);
}

.modal-welcome-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.modal-welcome-title span {
    font-size: 0.8rem;
    color: rgb(166, 166, 166);
    padding-left: 10px;
    text-transform: uppercase;
}

.modal-welcome-close {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.75rem;
    right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.4;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(104, 104, 104);
    border-image: initial;
    border-radius: 50%;
    transition: opacity 0.25s ease-in 0s;
}

.modal-welcome-close span {
    margin-top: 2px;
}

.modal-welcome-context {
    flex: 1 1;
    overflow-y: auto;
}

.modal-welcome-context h3 {
    padding: 1.5rem 0.5rem;
    text-align: center;
    line-height: 1.3;
    color: #666;
    font: inherit;
}

.modal-welcome-input {
    width: 85%;
    margin-left: 7.5%;
    margin-bottom: 1rem;
    height: 2.2rem;
    padding: 0 0.5rem;
    font-size: .9rem;
    font-family: 'Roboto',sans-serif;
    border: 1px solid #e1e5eb;
    border-radius: 0.25rem;
    color: #686868;
    -webkit-transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06);
    transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06);
}

.modal-welcome-input:focus {
    border-color: #456fed;
    box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06);
}

input:focus-visible {
    outline: none;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}

.modal-welcome-button {
    width: 85%;
    background-color: #27ae60 !important;
    cursor: pointer;
    margin-left: 7.5%;
    margin-bottom: 1.5rem;
    font-size: .9rem;
    font-family: 'Roboto',sans-serif;
    color: #fff;
    padding: 0.6rem 1rem;
    border: 0;
    border-radius: 0.25rem;
    -webkit-transition: background .2s ease-in;
    transition: background .2s ease-in;
    box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px rgba(0,0,0,.15);
}