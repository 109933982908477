.modal-c {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  display: -webkit-flex;
}

.modal-c.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-c.exit {
  opacity: 0;
}

.modal-c-content {
  width: 25rem;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  font-family: Roboto, sans-serif;
  transition: all 0.3s ease-in-out;
  transform: translateX(25rem);
  display: flex;
  flex-direction: column;
}

.modal-c.enter-done .modal-c-content {
  transform: translateX(0px);
}

.modal-c.exit .modal-c-content {
  transform: translateX(25rem);
}

.modal-c-header {
  height: 3rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: rgb(255, 255, 255);
}

.modal-c-title {
  align-self: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
}

.modal-c-title span {
  font-size: 0.8rem;
  color: rgb(166, 166, 166);
  text-transform: uppercase;
}

.modal-c-button {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.75rem;
  margin-left: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(104, 104, 104);
  border-image: initial;
  border-radius: 50%;
  transition: opacity 0.25s ease-in 0s;
}

.modal-c-button span {
  margin-top: -2px;
}

.modal-c-body {
  -webkit-flex: 1;
  flex: 1;
  overflow-y: auto;
}

.modal-c-body-empty {
  height: 100%;
  -webkit-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.modal-c-body-empty img {
  opacity: 0.3;
  margin-bottom: 0.5rem;
}

.modal-c-body-empty p {
  font-size: 0.95rem;
  line-height: 1.4;
  color: #686868;
}



ol,
ul {
  list-style: none;
  padding: 0;
}

.modal-c-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 -3px 7px rgba(0, 0, 0, 0.15);
  bottom: 0;
  z-index: 888;
  height: 3.75rem;
  width: 100%;
}

.modal-c-footer button {
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  border: 0;
  border-radius: 0.25rem;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 90%;
  background-color: #27ae60;
  padding: 0.75rem 1rem;
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}

.allowed-button {
  cursor: pointer;
  color: white;
}

.allowed-button:hover {
  background-color: #2abb67;
}

.modal-c-footer h3 {
  color: #fff;
  padding: 0.4rem 0.5rem;
  font-size: 0.8rem;
  margin: 0;
}

.modal-c-footer ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
}

.modal-c-footer ul li {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 1;
  flex: 1;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.modal-c-footer ul li a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
}

.modal-c-footer ul li a p {
  color: #e56970;
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
}

.modal-c-footer img {
  width: 1rem;
  height: auto;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  margin-right: 0.2rem;
}

.kapo-itemcart {
  background: #fff;
  margin: 1.25rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 3px 9px rgba(0,0,0,.05), 0 2.5px 6px rgba(0,0,0,.15);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
}

.kapo-itemcart > div {
  padding: 0.5rem 0.4rem;
}

.kapo-removeitem {
  position: absolute;
  right: -0.25rem;
  top: -0.35rem;
  font-size: .85rem;
  padding: 0.1rem 0.35rem 0.2rem;
  color: #e85b46;
  border-radius: 50%;
  cursor: pointer;
  background: #faebe8;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
}

.kapo-itemdescription {
  -webkit-flex: 1;
  flex: 1;
}

.kapo-itemvalue {
  min-width: 5.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.kapo-totalitem {
  font-size: .9rem;
  margin-bottom: 0.5rem;
}

.kapo-quantityitem {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.kapo-qtybtn {
  font-size: 1.3rem;
  color: #bababa;
  cursor: pointer;
  -webkit-transition: color .2s ease-in;
  transition: color .2s ease-in;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
}

.kapo-qtybtn:first-child {
  top: -0.65rem;
}

.kapo-qtybtn .disabled {
  opacity: .25;
}

.kapo-qtd {
  margin: 0 1rem;
  color: #868686;
  font-size: .95rem;
}