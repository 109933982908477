[data-animation="gelatine"] {
  animation: gelatine 0.5s 3;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

[data-animation="pulse"] {
  animation: pulse 1s 4 ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}

[data-animation="hithere"] {
  animation: hithere 1s ease 2;
}
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

[data-animation="grow"] {
  animation: grow 2s ease 1;
}
@keyframes grow {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

[data-animation="fadein"] {
  animation: fadein 2s linear 1;
}
@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

[data-animation="bounce"] {
  animation: bounce 2s ease 1;
}
@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}

[data-animation="bounce2"] {
  animation: bounce2 2s ease 1;
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

[data-animation="shake"] {
  animation: shake 1s ease 1;
}
@keyframes shake {
  0%, 100% {transform: translateX(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
  20%, 40%, 60%, 80% {transform: translateX(10px);}
}

[data-animation="flip"] {
	backface-visibility: visible !important;
	animation: flip 2s ease 1;
}
@keyframes flip {
	0% {
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		transform: perspective(400px) rotateY(360deg) scale(.95);
		animation-timing-function: ease-in;
	}
	100% {
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}