.kh-width {
    width: 44rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; */
    visibility: hidden;
}

.kapo_cntbtn {
    text-align: center;
    padding-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 0.5px solid blue !important;
    border-radius: 4px;
    z-index: 701 !important;
    position: relative;

}
.kapo_cntbtn:focus-visible{
    outline: 0px;
}

.kapo-overlay{
    background-color: rgba(0,0,0);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 700;
    opacity: 0;
    display: none;
    -webkit-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;
}

@media screen and (min-width: 1400px) {
    .kh-width{
        width: calc(100vw*4/5);
    }
}

@media screen and (max-width: 44rem) {
    .kh-width {
        width: 100%;
    }

    .kh-checkout {
        margin-right: 10px;
    }

    .kh-menu {
        margin-left: 10px;
    }
}

@media screen and (max-width: 500px) {
    .kapo_cntbtn {
        padding-left: 5px;
    }

    .kh-share {
        margin-left: 10px;
    }

    .kh-search {
        margin-left: 10px;
    }

    .kh-go {
        margin-left: 10px;
    }
}