.modal-sh {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  display: -webkit-flex;
}

.modal-sh.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-sh.exit {
  opacity: 0;
}

.modal-sh-content {
  border-radius: 0.375rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 2.5px 6px rgba(0, 0, 0, 0.25);
  width: 20rem;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  transition: all 1s ease-in-out;
  display: flex;
  flex-direction: column;
}

.modal-sh-header {
  height: 3rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: rgb(255, 255, 255);
  -webkit-flex-direction: row;
}

.modal-sh-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0%;
}

.modal-sh-title span {
  font-size: 0.8rem;
  color: rgb(166, 166, 166);
  padding-left: 10px;
  text-transform: uppercase;
}

.modal-sh-close {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(104, 104, 104);
  border-image: initial;
  border-radius: 50%;
  transition: opacity 0.25s ease-in 0s;
}

.modal-sh-close span {
  margin-top: -2px;
}

.modal-sh-body {
  -webkit-flex: 1;
  flex: 1;
  overflow-y: auto;
}

.modal-sh-body h3 {
  padding: 1.5rem 0.5rem;
  text-align: center;
  line-height: 1.3;
  color: #666;
  font: inherit;
}

.modal-sh-body-elements {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  align-items: center;
  padding-bottom: 0.75rem;
}

.modal-sh-body-elements img {
  width: 3.25rem;
  height: auto;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.modal-sh-footer {
  display: -webkit-flex;
  display: flex;
  -webki-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  opacity: 0;
}

.modal-sh-footer input {
  cursor: pointer;
}

.modal-sh-footer p {
  margin-left: 0.25rem;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}
