.modal-s {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  display: -webkit-flex;
}

.modal-s.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-s.exit {
  opacity: 0;
}

.modal-s-content {
  width: 25rem;
  height: 100vh;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  transition: all 0.3s ease-in-out;
  transform: translateX(-400px);
  display: flex;
  flex-direction: column;
}

.modal-s.enter-done .modal-s-content {
  transform: translateX(0px);
}

.modal-s.exit .modal-s-content {
  transform: translateX(-400px);
}

.modal-s-header {
  height: 3rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: rgb(255, 255, 255);
}

.modal-s-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
}

.modal-s-title span {
  font-size: 0.8rem;
  color: rgb(166, 166, 166);
  text-transform: uppercase;
}

.modal-s-close {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(104, 104, 104);
  border-image: initial;
  border-radius: 50%;
  transition: opacity 0.25s ease-in 0s;
}

.modal-s-body {
  -webkit-flex: 1;
  flex: 1;
  overflow-y: auto;
}

.modal-s-content-div {
  height: 4rem;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}

.modal-s-content-input {
  width: calc(100% - 4.5rem);
  padding-right: 1.5rem;
  height: 2.2rem;
  padding: 0 0.5rem;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  color: #686868;
  -webkit-transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.modal-s-search-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  padding: 0.75rem;
}

.modal-s-search-text p {
  text-align: center;
  font-size: 0.95rem;
  line-height: 1.4;
  color: #686868;
}
