.modal-pro {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    display: -webkit-flex;
}

.modal-pro.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.modal-pro.exit {
    opacity: 0;
}

.modal-pro-content {
    overflow: hidden;
    border-radius: 0.375rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 2.5px 6px rgba(0, 0, 0, 0.25);
    width: 20rem;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
}

.modal-pro-header {
    height: 6rem;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    background: rgb(255, 255, 255);
    -webkit-flex-direction: row;
}

.modal-pro-title {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.modal-pro-title h1 {
    font-size: 1rem;
    color: rgb(166, 166, 166);
    text-transform: uppercase;
}

.modal-pro-body {
    margin-bottom: 3rem;
    -webkit-flex: 1;
    flex: 1;
}

select:focus-visible {
    outline: none;
}

#sizeCarousel {
    --f-carousel-slide-width: calc(100% / 4);
}

#sizeCarousel .f-carousel__nav .f-button.is-prev {
    margin-left: -15px;
}

#sizeCarousel .f-carousel__nav .f-button.is-next {
    margin-right: -15px;
}

#colorCarousel {
    --f-carousel-slide-width: calc(100% / 3);
}

#colorCarousel .f-carousel__nav .f-button.is-prev {
    margin-left: -15px;
}

#colorCarousel .f-carousel__nav .f-button.is-next {
    margin-right: -15px;
}

.modal-pro-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.3rem;
}


.modal-pro-footer > button,
.modal-pro-footer > div > button{
    font-size: 0.9rem;
    font-family: "Roboto", sans-serif;
    border: 0;
    border-radius: 0.25rem;
    -webkit-transition: background 0.2s ease-in;
    transition: background 0.2s ease-in;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    background-color: #27ae60;
    padding: 0.75rem 1rem;
    color: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
}

.modal-pro-footer > div {
    display: flex;
    flex-wrap: nowrap;
}

.allowed-button {
    cursor: pointer !important;
    color: white !important;
}

.allowed-button:hover {
    background-color: #2abb67;
}

.kapo-quantityitem {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 1rem;
}

.kapo-qtybtn:first-child {
    transition: translateY(100%);
}