.loader {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 4rem;
}

.loader div {
    width: 200px;
    height: 200px;
    border: 10px solid #EAF0F6;
    border-radius: 50%;
    border-top: 10px solid #333333ee;
    animation: spinner 1s linear infinite;
}

.loader p {
    width: 100%;
    text-align: center;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(160deg);}
    50% { transform: rotate(240deg); }
    100% { transform: rotate(360deg); }
}